* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}
.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel] {
  height: 3.5rem;
  width: 24rem;
  margin-bottom: 1rem !important;
  border-radius: 5px;
  border: 1px solid rgb(182,181,181);
}